import { GridItem, SimpleGrid } from '@chakra-ui/react'
import type { JoinedWidgetType, StorefrontWidget } from 'ecosystem'
import { createCn, parseObjectToClassnames, showsOnMobile } from 'shared-utils'
import type { FC, ReactElement } from 'react'
import GrandSection from 'ui/storefront/layout/GrandSection'
import type { WidgetImplementation } from '../utils'

const cn = createCn('joined-widget')

const WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  TITLE: 'title',
  TITLE_DECORATION: 'title_decoration',
  TITLE_CONTAINER: 'title_container',
  ITEMS_WRAPPER: 'items_wrapper',
  ITEM_WRAPPER: 'item_wrapper'
}

type JoinedWidgetProps = WidgetImplementation<
  StorefrontWidget<JoinedWidgetType<StorefrontWidget<any>>>
> & {
  joinedFactory: (widget: StorefrontWidget<unknown>) => ReactElement
}

const JoinedWidget = ({ widget, joinedFactory }: JoinedWidgetProps) => {
  const { relatedSharedWidgets, isWrapped, title, columnsNumber, columnsNumberMobile } =
    widget.widget

  if (!isWrapped) {
    return (
      <SharedWidgets
        items={relatedSharedWidgets}
        {...{ joinedFactory, columnsNumber, columnsNumberMobile }}
      />
    )
  }

  return (
    <>
      {(Boolean(relatedSharedWidgets.length) || Boolean(title)) && (
        <GrandSection
          className={cn(WIDGET_CN.SECTION)}
          contentContainerProps={{
            className: cn(WIDGET_CN.SECTION_CONTAINER),
            spacing: 0,
            gap: 10
          }}
          sectionId="joined-widget"
          titleProps={{
            title,
            className: cn(WIDGET_CN.TITLE),
            decorationProps: {
              className: cn(WIDGET_CN.TITLE_DECORATION)
            },
            containerProps: {
              className: cn(WIDGET_CN.TITLE_CONTAINER)
            }
          }}>
          <SharedWidgets
            items={relatedSharedWidgets}
            {...{ joinedFactory, columnsNumber, columnsNumberMobile }}
          />
        </GrandSection>
      )}
    </>
  )
}

export default JoinedWidget

interface SharedWidgetsProps {
  items: StorefrontWidget<any>[]
  joinedFactory: (widget: StorefrontWidget<unknown>) => ReactElement
  columnsNumber?: string
  columnsNumberMobile?: string
}

const SharedWidgets: FC<SharedWidgetsProps> = ({
  joinedFactory,
  items,
  columnsNumber,
  columnsNumberMobile
}) => {
  if (!items?.length) {
    return null
  }

  return (
    <SimpleGrid
      className={cn(WIDGET_CN.ITEMS_WRAPPER)}
      columns={{
        base: Number(columnsNumberMobile),
        md: Number(columnsNumber)
      }}
      gap={5}>
      {items.map((w) => {
        return (
          <GridItem
            className={cn(WIDGET_CN.ITEM_WRAPPER)}
            display={{
              base: showsOnMobile(w),
              md: 'initial'
            }}
            key={w.id}
            sx={parseObjectToClassnames(w.widget.styling)}>
            {joinedFactory(w)}
          </GridItem>
        )
      })}
    </SimpleGrid>
  )
}
